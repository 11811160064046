import React from 'react';

import styled from '@emotion/styled';

import { Box, Column, mq } from '@clutter/clean';
import { ResponsiveImage } from '@shared/responsive_image';
import { useIsomorphicBreakpoints } from '@utils/hooks/use_breakpoints';

import { BadgeOne, BadgeTwo } from './badge';

import heroAppPreview from '@images_responsive/home/hero_app_preview.jpg';
import heroMoversLoadingTruck from '@images_responsive/home/hero_movers_loading_truck.jpg';

const RoundedImage = styled(ResponsiveImage)`
  border-radius: 8px;
  max-width: unset;
  width: 100%;
`;

const LeftBadgeImage = styled(BadgeOne)`
  position: absolute;
  max-width: 164px;
  max-height: 164px;
  min-width: 92px;
  min-height: 92px;
  z-index: 1;

  ${mq({
    right: ['-16px', null, '-30px'],
    top: [null, null, '-44px'],
    bottom: ['-12px', null, 'initial'],
    height: ['15vw', null, '164px'],
    width: ['15vw', null, '164px'],
  })}
`;

const RightBadgeImage = styled(BadgeTwo)`
  position: absolute;
  max-width: 160px;
  max-height: 160px;
  z-index: 1;

  ${mq({
    right: [null, null, '-32px'],
    left: ['-32px', null, 'initial'],
    top: ['-60px', null, '-60px'],
    height: ['114px', null, '13vw'],
    width: ['114px', null, '13vw'],
  })}
`;

const ImageContainerDesktop = styled(Column)`
  ${mq({
    display: ['none', null, 'flex'],
    alignItems: [null, null, 'center', 'flex-end'],
    margin: [null, null, '0 52px 72px -52px', '0 80px 72px -80px'],
  })}

  #hero-truck-desktop {
    position: relative;
    width: calc(60% - 12px);
  }

  #hero-man-desktop {
    position: relative;
    width: calc(40% - 12px);
  }
`;

const ImageContainerMobile = styled.div`
  max-width: 867px;
  margin-bottom: 24px;
  align-items: center;

  ${mq({
    display: ['flex', null, 'none'],
    margin: ['32px -32px 24px'],
  })}

  #hero-truck-mobile {
    position: relative;
    width: 55%;
    margin: 0 24px 0 0;
    flex-shrink: 0;
  }

  #hero-man-mobile {
    position: relative;
    width: 100%;
  }
`;

export const MobileImages = () => {
  const { isDesktop } = useIsomorphicBreakpoints();

  return (
    <ImageContainerMobile>
      <div id="hero-truck-mobile">
        <RoundedImage
          image={heroMoversLoadingTruck}
          alt="Clutter movers unloading storage boxes for relocation"
          loading={isDesktop ? 'lazy' : 'eager'}
          sizes="55vw"
          baseImageSizes={[200, 400, 600, 800]}
        />
        <LeftBadgeImage />
      </div>
      <div id="hero-man-mobile">
        <RoundedImage
          image={heroAppPreview}
          alt="Clutter Storage and Moving Services"
          loading={isDesktop ? 'lazy' : 'eager'}
          sizes="45vw"
          baseImageSizes={[200, 400, 600, 800]}
        />
        <RightBadgeImage />
      </div>
    </ImageContainerMobile>
  );
};

export const DesktopImages = () => {
  const { isDesktop } = useIsomorphicBreakpoints();
  return (
    <ImageContainerDesktop widths={[12, 12, 6]}>
      <Box.Flex
        alignItems="flex-end"
        justifyContent="space-between"
        width="100%"
      >
        <div id="hero-truck-desktop">
          <RoundedImage
            image={heroMoversLoadingTruck}
            alt="Clutter movers unloading storage boxes for relocation"
            loading={isDesktop ? 'eager' : 'lazy'}
            sizes="450px"
            baseImageSizes={[450, 900, 1350]}
          />
          <LeftBadgeImage />
        </div>
        <div id="hero-man-desktop">
          <RoundedImage
            image={heroAppPreview}
            alt="Clutter Storage and Moving Services"
            loading={isDesktop ? 'eager' : 'lazy'}
            sizes="300px"
            baseImageSizes={[300, 600, 900]}
          />
          <RightBadgeImage />
        </div>
      </Box.Flex>
    </ImageContainerDesktop>
  );
};
