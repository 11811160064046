import React, { useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Box, ButtonLink, SpinDotsLoader, Text } from '@clutter/clean';
import {
  Callback__RequestKindEnum,
  CallbackWindow,
  useCallbackRequestQuery,
} from '@graphql/platform';

import { formatDateTime } from './util';
import { WindowEditForm } from './window_edit_form';

export const Content: React.FC = () => {
  const [selectedWindow, setSelectedWindow] = useState<CallbackWindow>();
  const [changeSubmitted, setChangeSubmitted] = useState<boolean>(false);
  const [cancelSubmitted, setCancelSubmitted] = useState<boolean>(false);
  const { pathname } = useLocation();
  const slug = pathname.split('/').pop() || '';
  const { data, loading } = useCallbackRequestQuery({
    variables: { slug },
    onCompleted: (data) => {
      const { callbackRequest: cr } = data;
      if (cr) {
        setSelectedWindow({
          time: cr.scheduledAt,
          duration: cr.windowSize,
        });
      }
    },
  });

  const callbackRequest = data?.callbackRequest;

  if (loading) {
    return (
      <Box height="100vh">
        <Box.Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height="100%"
          padding="0 0 400px"
        >
          <SpinDotsLoader />
        </Box.Flex>
      </Box>
    );
  }

  return (
    <>
      <Box maxWidth={[null, '720px']} margin={[null, '0 auto']}>
        {changeSubmitted ? (
          <>
            <Text.Title size="medium">
              Your call back has been successfully rescheduled
            </Text.Title>
            <Box margin="16px 0 24px">
              <Text.Body>
                You will receive a call back from us{' '}
                {callbackRequest?.kind ===
                Callback__RequestKindEnum.Immediate ? (
                  'within the next 10 minutes'
                ) : (
                  <>on {formatDateTime(callbackRequest!.scheduledAt)}</>
                )}
                .
              </Text.Body>
            </Box>
            <Box>
              {callbackRequest?.kind === Callback__RequestKindEnum.Immediate ? (
                <ButtonLink size="small" href="/">
                  Continue shopping
                </ButtonLink>
              ) : (
                <ButtonLink size="small" href={`/cr/${slug}`}>
                  Manage call back
                </ButtonLink>
              )}
            </Box>
          </>
        ) : cancelSubmitted ? (
          <>
            <Text.Title size="medium">
              Your call back has been successfully canceled
            </Text.Title>
            <Box margin="16px 0">
              <Box margin="16px 0 24px">
                <Text.Body>
                  You will no longer receive a call back on{' '}
                  {formatDateTime(callbackRequest!.scheduledAt)}.
                </Text.Body>
              </Box>
              <ButtonLink size="small" href="/">
                Continue shopping
              </ButtonLink>
            </Box>
          </>
        ) : callbackRequest ? (
          <WindowEditForm
            callbackRequest={callbackRequest}
            selectedWindow={selectedWindow}
            onSelectedWindowChange={(w) => setSelectedWindow(w)}
            onSubmitted={() => setChangeSubmitted(true)}
            onCanceled={() => setCancelSubmitted(true)}
          />
        ) : (
          <>
            <Text.Title size="medium">
              Invalid or expired call back link
            </Text.Title>
            <Box margin="16px 0 24px">
              <Text.Body>
                This call back request has either already passed or does not
                exist.
              </Text.Body>
            </Box>
            <ButtonLink size="small" href="/">
              Continue shopping
            </ButtonLink>
          </>
        )}
      </Box>
    </>
  );
};
