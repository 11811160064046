import React, { useState } from 'react';

import { DateTime, Duration } from 'luxon';

import { Box, Button, COLORS, Text } from '@clutter/clean';
import {
  Callback__Request,
  CallbackWindow,
  Status,
  useCallbackRequestCancelMutation,
  useCallbackRequestUpdateMutation,
} from '@graphql/platform';
import { useTrack } from '@root/initializers/wt';
import { CallbackWindows } from '@shared/qualified_contact_widget/callback_windows';

import { formatDateTime } from './util';

const SUBMIT_BUTTON_LABEL = 'Submit';
const CANCEL_BUTTON_LABEL = 'Cancel scheduled call back';

export const WindowEditForm: React.FC<{
  callbackRequest: Callback__Request;
  selectedWindow?: CallbackWindow;
  onSelectedWindowChange: (window: CallbackWindow) => void;
  onSubmitted: () => void;
  onCanceled: () => void;
}> = ({
  callbackRequest,
  selectedWindow,
  onSelectedWindowChange,
  onSubmitted,
  onCanceled,
}) => {
  const [cancelRequest, { loading: loadingCancel }] =
    useCallbackRequestCancelMutation();
  const [updateRequest, { loading: loadingUpdate }] =
    useCallbackRequestUpdateMutation();
  const [submitError, setSubmitError] = useState<string | undefined>();
  const [cancelError, setCancelError] = useState<string | undefined>();

  const track = useTrack({ container: 'callback_request_form' });

  const loading = loadingCancel || loadingUpdate;

  const scheduledAtChanged =
    DateTime.fromISO(selectedWindow?.time || '').toMillis() !==
    DateTime.fromISO(callbackRequest.scheduledAt).toMillis();

  const clearErrors = () => {
    setSubmitError(undefined);
    setCancelError(undefined);
  };

  const onUpdateRequest = async () => {
    clearErrors();
    track({
      action: 'click',
      objectName: 'callback_edit_submit',
      objectType: 'button',
      label: SUBMIT_BUTTON_LABEL,
    });

    if (callbackRequest && selectedWindow) {
      const oldCallbackTime = callbackRequest?.scheduledAt;
      const oldDuration = callbackRequest?.windowSize;
      const { data } = await updateRequest({
        variables: {
          slug: callbackRequest.slug,
          scheduledAt: selectedWindow.time,
        },
      });
      if (data?.callbackRequestUpdate?.status === Status.Ok) {
        track({
          action: 'display',
          objectName: 'callback_edit_success_message',
          metadata: {
            request_slug: callbackRequest.slug,
            old_callback_time: oldCallbackTime,
            old_duration: oldDuration,
            callback_time: selectedWindow?.time,
            duration: selectedWindow?.duration,
          },
        });
        onSubmitted();
      } else {
        let error = `Failed to change callback time.`;
        if (data?.callbackRequestUpdate?.error) {
          error += ` ${data.callbackRequestUpdate.error}`;
        }
        setSubmitError(error);
      }
    }
  };

  const onCancelRequest = async () => {
    clearErrors();
    track({
      action: 'click',
      objectName: 'callback_cancel_submit',
      objectType: 'button',
      label: CANCEL_BUTTON_LABEL,
    });
    const { data } = await cancelRequest({
      variables: { slug: callbackRequest.slug },
    });
    if (data?.callbackRequestCancel?.status === Status.Ok) {
      track({
        action: 'display',
        objectName: 'callback_cancel_success_message',
        metadata: {
          request_slug: callbackRequest.slug,
          callback_time: selectedWindow?.time,
          duration: selectedWindow?.duration,
        },
      });
      onCanceled();
    } else {
      let error = `Failed to cancel callback request.`;
      if (data?.callbackRequestCancel?.error) {
        error += ` ${data.callbackRequestCancel.error}`;
      }
      setCancelError(error);
    }
  };

  return (
    <>
      <Text.Title size="large">Manage your scheduled call back</Text.Title>
      <Box margin="16px 0">
        <Text.Body>
          {scheduledAtChanged ? (
            <>
              Your call back will be changed from{' '}
              {formatDateTime(callbackRequest.scheduledAt)} to{' '}
              <strong>
                {selectedWindow?.duration ===
                Duration.fromObject({ minutes: 10 }).toISO()
                  ? 'within the next 10 minutes'
                  : formatDateTime(selectedWindow?.time || '')}
              </strong>
              . Click submit to confirm.
            </>
          ) : (
            <>
              Your call back is currently scheduled for{' '}
              {formatDateTime(callbackRequest.scheduledAt)}. You can change the
              scheduled time below.
            </>
          )}
        </Text.Body>
      </Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onUpdateRequest();
        }}
      >
        <Box margin="24px 0 16px">
          <CallbackWindows
            textSize="medium"
            scrollable={false}
            selectedWindow={selectedWindow}
            onSelect={(cbw) => onSelectedWindowChange(cbw)}
          />
        </Box>

        <Button
          kind="primary"
          disabled={!scheduledAtChanged || loading}
          loading={loadingUpdate}
          type="submit"
          fullWidth
        >
          {SUBMIT_BUTTON_LABEL}
        </Button>

        {submitError && (
          <Box margin="12px 0 0">
            <Text.Callout color={COLORS.toucan}>{submitError}</Text.Callout>
          </Box>
        )}
      </form>
      <Box margin="36px 0 0">
        <Text.Body>
          If you no longer wish to have a call back, you can cancel any time
          before your scheduled time.
        </Text.Body>
      </Box>
      <Box margin="16px auto">
        <Button
          kind="destructive"
          size="small"
          onClick={() => onCancelRequest()}
          loading={loadingCancel}
          disabled={loading}
        >
          {CANCEL_BUTTON_LABEL}
        </Button>
        {cancelError && (
          <Box margin="12px 0 0">
            <Text.Callout color={COLORS.toucan}>{cancelError}</Text.Callout>
          </Box>
        )}
      </Box>
    </>
  );
};
